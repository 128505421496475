import { Component } from '@angular/core'
import { appConfig } from '@configuration/app-config'
import { CategoriesService } from '@service/categories.service'
import { MetricService } from '@service/metric.service'
import { MobileService } from '@service/mobile.service'
import { ScrollService } from '@service/scroll.service'
import { StateService } from '@service/state.service'
import { UserService } from '@service/user/user.service'

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {

  public socialMediaNumbers = [];

  constructor(
    public MobileService: MobileService,
    public ScrollService: ScrollService,
    public userService: UserService,
    public StateService: StateService,
    public CategoriesService: CategoriesService,
    private metricService: MetricService,
  ) {
  }

  async ngOnInit(): Promise<void> {
    await this.fetchMetrics();
  }

  private async fetchMetrics(): Promise<void> {
    try {
      const metrics = await this.metricService.getMetrics();

      this.populateSocialMediaNumbers(metrics);

    } catch (error) {
      console.error("failed to fetch metrics:", error);
      this.populateSocialMediaNumbers(null);
    }
  }

  private populateSocialMediaNumbers(metrics: any): void {
    this.socialMediaNumbers = [
      {
        number: metrics?.members ?? appConfig.members,
        where: "Registrierte",
        who: "Mitglieder",
        image: '/build/assets/images/logo.svg'
      },
      {
        number: metrics?.members_instagram ?? appConfig.members_instagram,
        where: "Instagram",
        who: "Abonnenten",
        icon: "fab fa-instagram",
        mobileIcon: "fab fa-instagram fa-3x",
        link: "https://www.instagram.com/zimmerertreffpunkt/"
      },
      {
        number: metrics?.members_facebook ?? appConfig.members_facebook,
        where: "Facebook",
        who: "Abonnenten",
        mobileIcon: "fab fa-facebook fa-3x",
        icon: "fab fa-facebook",
        link: "https://www.facebook.com/ZimmererTreffpunkt/"
      },
      {
        number: metrics?.members_linkedin ?? appConfig.members_linkedin,
        where: "Linkedin",
        who: "Abonnenten",
        mobileIcon: "fab fa-linkedin fa-3x",
        icon: "fab fa-linkedin",
        link: "https://www.linkedin.com/company/zimmerer-treffpunkt/"
      },
      {
        number: `${appConfig.members_facebook_group}`,
        where: "Facebook-Mitgliedergruppe",
        who: "Facebook-Mitgliedergruppe",
        link: "https://www.facebook.com/groups/725765151265175/",
        image: '/build/assets/images/users_group.svg'
      },
    ];
  }

  public goto(state) {
    this.StateService.goto(state)
      .then(() => {
        this.ScrollService.scrollToTop()
      })
  }

  public getCurrentYear(): number {
    return new Date().getFullYear();
  }

  get footerFirstText(): string {
    return this.userService.isLoggedIn
      ? 'Poste Tipps & Tricks, stelle dich vor oder zeige Bilder von deiner Arbeit. Im Blog kannst Du selbst Beiträge erstellen. Verkaufe Zimmereibedarf im Marktplatz oder erstelle eine Anzeige in der Jobbörse.'
      : 'Jetzt kostenlos Mitglied werden! Profitiere auch Du vom großen Netzwerk im Zimmerer-Treffpunkt. Registriere dich jetzt kostenlos und melde dich an. Als Zimmerer oder Zimmerin gehörst Du dazu!'
  }

  get footerSecondText(): string {
    return this.userService.isLoggedIn
      ? 'Wer fleißig ist gewinnt: Posten, kommentieren und Woodcoins sammeln. Erfahre jetzt mehr über das Bonussystem!'
      : 'Wer fleißig ist gewinnt: Posten, kommentieren und Bonuspunkte sammeln. Räume tolle Preise ab!';
  }
}
