import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { DialogService } from '@service/dialog.service';
import { UserService } from '@service/user/user.service';
import { filter, Observable, map, take, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    public router: Router,
    public userService: UserService,
    public dialogService: DialogService,
  ) {
  }

  canActivate(): Observable<boolean | UrlTree> {
    return this.userService.userStateObservable.pipe(
      filter(state => state.status === 'available'),
      // magic
      tap(console.log),
      map(user => user.user !== null),
      take(1),
      tap(allowed => {
        if (!allowed) {
          this.router.navigate(['/news']).then(() => {
            this.dialogService.loginRegistrationDialog()
          });
        }
      }),
    );
  }
}