import { appConfig } from "@configuration/app-config"

export function getFileExtension(filename: string): any {
    return filename.slice(((filename.lastIndexOf('.') - 1) >>> 0) + 2)
}

export function filesizeAllowed(imageInput: any): boolean {
    const largeImagesExists = Array.from(imageInput).find((file: any) => file.size > appConfig.maxSizeImage)

    if (largeImagesExists) {
        return false
    }

    return true
}