import { Injectable } from '@angular/core'
import moment from 'moment'
import { BehaviorSubject, map, Observable, shareReplay, skip, startWith, Subject } from 'rxjs'
import { RestService } from '@service/rest/rest.service'
import { User } from '@model/user/user'
import { CompanyProfile } from '@model/company-profile/company-profile'
import { SerializationService } from 'aautil'
import { imageDefinitions } from '@configuration/image-definitions'
import { Token } from '@model/token'
import { ImageUploadService } from '@interface/image-upload-service'
import { LoadingService } from '@service/loading.service'
import { TransitionHookService } from '@service/transition-hook.service'
import store from 'store'
import { SentryService } from '@service/sentry.service'
import { ToastService } from '@service/toast/toast.service'
import { Entity } from '@model/entities/entity'
import { UserImageKeys } from '@type/user-image-keys'
import { Router } from '@angular/router'
import { DialogService } from '@service/dialog.service'
import { ImageConfig } from '@model/image-config/image-config'

type UserState
  = { status: 'loading' }
  | { status: 'available', user: User | null };

/*
  THIS SERVICE SHOULD NOT INJECT OTHER SERVICES AND ITSELF BE INJECTED
  https://gitea.aceart.de/aceArtGmbH/zimmerer-treffpunkt/src/branch/master/README.md
  chapter "How to avoid Angular Service Circular Dependency"
*/
@Injectable({
  providedIn: 'root',
})
export class UserService extends ImageUploadService {

  // this is a very specific observable that only fires exactly when the user logs in
  // (not when he was recovered from store / toke etc)
  public userLoggedInObservable: Subject<User> = new Subject()

  // this is a very specific observable that only fires exactly when the user logs out
  public userLoggedOutObservable: Subject<null> = new Subject()

  // this is a generic "user changed" observable that fires often
  // i.e. user logged in / out / new companyprofile etc.
  public userObservable: BehaviorSubject<User> = new BehaviorSubject(null)

  // second user observable to check if the initial state of user is loaded
  public userStateObservable: Observable<UserState>;

  public user: User = null

  private cookie_consent: boolean = null

  constructor(
    private restService: RestService,
    private serializationService: SerializationService,
    private loadingService: LoadingService,
    private transitionHookService: TransitionHookService,
    private sentryService: SentryService,
    private Router: Router,
    protected toastService: ToastService,
    private DialogService: DialogService
  ) {
    super(
      toastService
    )

    this.userStateObservable = this.userObservable.pipe(
      skip(1),
      map(user => {
        return <UserState>{ status: 'available', user: user };
      }),
      startWith(<UserState>{ status: 'loading' }),
      shareReplay(1),
    );

    // magic
    // ensure the observable remains "hot" (actively producing values)
    this.userStateObservable.subscribe();
  }

  /*
    get access token from ls
  */
  public getAccessToken(): String {
    let token = store.get('access_token')
    if (!token)
      return ""
    else
      return token.token
  }

  /*
    this is the main method periodically called by the app to watch if the user is fetched and token is not expired etc.
  */
  public tokenHealtCheck(): Promise<void> {
    return new Promise((resolve, reject) => {

      const token = store.get('access_token')

      // if there is no token -> alrighty user is not logged in and never was
      if (!token) {
        this.userObservable.next(null);
        resolve()
      }

      // if there is one
      else {

        // and it is expired -> kill user and tell him
        if (token.expires < Date.now() / 1000) {
          this.deleteLocalUser()
          this.Router.navigate(['/news'])
          this.DialogService.showLoginDialog()
        }

        // if its not expired but we dont have the user yet -> fetch it
        else if (!this.user) {
          this.refreshUser()
            .then(() => {
              resolve()
            })
        }

        // token set + not expired + user fetched -> alrighty
        else
          resolve()
      }

    })
  }

  /*
    Returns the `id` of the current user. `null` if nobody is logged in
  */
  public getUserID(): number | null {
    if (this.user) {
      return this.user.id
    } else {
      return null
    }
  }

  public get isLoggedIn(): boolean {
    return this.user ? true : false
  }

  /*
    pull already logged in user new from server
  */
  public refreshUser(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.restService.post('frontendUser/me', {}, imageDefinitions.user.all_with_high_res_profile)
        .then((unserializedUser: Object) => {

          this.serializationService.deserialize(User, unserializedUser)
            .then((deserializedUser: User) => {
              this.setUser(deserializedUser)
              this.updateLocalStorageUser(deserializedUser)
              resolve()
            })
            .catch(err => {
              this.sentryService.silentCaptureException(err)
              reject(err)
            })

        })
        .catch(err => reject(err))
    })
  }

  public uploadImage(
    formData,
    purpose: UserImageKeys
  ): Promise<null> {
    return new Promise((resolve, reject) => {

      if (purpose == 'COMPANY_PRODUCT' || purpose == 'COMPANY_CERTIFICATE') {

        this.restService.post('frontendUser/uploadCompanyImages', formData, imageDefinitions.companyProfile.default, true)
          .then((unserialized_user: User) => {

            this.serializationService.deserialize(User, unserialized_user)
              .then((deserialized: User) => {
                this.setCompanyProfile(deserialized.company_profile)
                resolve(null)
              })
              .catch((err) => {
                this.sentryService.silentCaptureException(err)
                reject(err)
              })

          })
          .catch(err => reject())

      }
      else if (purpose == 'PROFILE_CERTIFICATE') {

        this.restService.post('frontendUser/uploadImages', formData, imageDefinitions.user.all_with_high_res_profile, true)
          .then((user: User) => {
            this.serializationService.deserialize(User, user)
              .then((deserialized: User) => {
                this.setUser(deserialized)
                resolve(null)
              })
              .catch((err) => {
                this.sentryService.silentCaptureException(err)
                reject(err)
              })
          })
          .catch(err => reject())

      }

      else if (purpose == 'PROFILE_IMAGE' || purpose == 'COMPANY_IMAGE' || purpose == 'PROFILE_WALL_IMAGE' || purpose == 'COMPANY_WALL_IMAGE') {

        let url = 'frontendUser/'
        if (purpose.startsWith('COMPANY')) {
          url += 'uploadCompanyImages'
        } else {
          url += 'uploadImages'
        }

        // send
        this.restService.post(url, formData, imageDefinitions.user.all_with_high_res_profile, true)
          .then((unserializedUser: Object) => {

            // deserialize
            this.serializationService.deserialize(User, unserializedUser)
              .then((user: User) => {

                // set
                this.setUser(user)
                this.updateLocalStorageUser(user)
                resolve(null)
              })
              .catch(err => {
                this.sentryService.silentCaptureException(err)
                reject(err)
              })
          })
          .catch(err => reject())

      }

    })
  }

  private calculateAccessTokenEnd(expires: number): number {
    return moment()
      .add(expires, 'seconds')
      .unix()
  }

  // SERVER SIDE REQUEST SETTERS
  ///////////////////////////////////////

  public login(email: string, password: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const data = {
        email,
        password,
      }

      this.restService.post('auth/login', data, imageDefinitions.user.all_with_high_res_profile)
        .then((response: any) => {

          if (['NO_MATCH', 'ACCOUNT_NOT_CONFIRMED'].includes(response))
            reject(response)
          else {
            this.setUserFromBackendWithToken(response)
              .then(() => {
                this.userLoggedInObservable.next(this.user)
                resolve()
              })
          }
        })
        .catch(err => reject(err))
    })
  }

  public logout(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.transitionHookService.endBeforeLeaveHook()
      this.logoutinternal().then(() => {

        this.userLoggedOutObservable.next(null)

        resolve()
      })
    })
  }

  public logoutinternal(): Promise<void> {
    return new Promise((resolve, reject) => {

      this.loadingService.startLoad()
      this.restService.post('auth/logout', {})
        .then(() => {
          this.deleteLocalUser()
          this.Router.navigate(['/blog'])
          resolve()
        })
        .catch(err => reject(err))
        .finally(() => {
          this.loadingService.stopLoad()
        })
    })
  }

  public updateUser(user: User): Promise<User> {
    return new Promise((resolve, reject) => {

      // serialize
      this.serializationService.serialize(user, User)
        .then((serializedUser: User) => {

          // send
          this.restService.put('frontendUser/updateUser', serializedUser, imageDefinitions.user.all_with_high_res_profile)
            .then((rawUser: Object) => {

              // deserialize
              this.serializationService.deserialize(User, rawUser)
                .then(deserializedUser => {
                  this.setUser(deserializedUser)
                  resolve(deserializedUser)
                })
                .catch(err => {
                  this.sentryService.silentCaptureException(err)
                  reject(err)
                })
            })
            .catch(err => {
              reject(err)
            })
        })
        .catch(err => {
          this.sentryService.silentCaptureException(err)
          reject(err)
        })
    })
  }

  public deleteProfileImage(): Promise<User> {
    return new Promise((resolve, reject) => {

      if (!this.user.profileImage) {
        console.error("error someone tried to access deleteProfileImage for a user without a profile image")
        reject()
      }

      const data = {
        image_ids: [this.user.profileImage.image.id],
      }

      // send delete
      this.restService.post('frontendUser/deleteImages', data, imageDefinitions.user.all_with_high_res_profile)
        .then((response: Object) => {

          // deserialize
          this.serializationService.deserialize(User, response)
            .then((deserialized: User) => {
              this.setUser(deserialized)
              resolve(deserialized)
            })
            .catch(err => {
              this.sentryService.silentCaptureException(err)
              reject(err)
            })
        })
        .catch(err => reject(err))
    })
  }

  public deleteWallImage(): Promise<void> {
    return new Promise((resolve, reject) => {

      if (!this.user)
        return

      if (!this.user.profileWallImage)
        return

      // send
      this.restService.post('frontendUser/deleteImages', { image_ids: [this.user.profileWallImage.image.id] }, imageDefinitions.user.all_with_high_res_profile)
        .then((response: User) => {

          // deserialize
          this.serializationService.deserialize(User, response)
            .then((user: User) => {

              // publish
              this.setUser(user)
              resolve()
            })
            .catch(err => {
              this.sentryService.silentCaptureException(err)
              reject(err)
            })
        })
        .catch(err => reject(err))
    })
  }

  // CLIENT LOCAL SETTERS
  ///////////////////////////////////////
  private setUser(user: User) {
    this.user = user
    this.userObservable.next(this.user)
  }

  private setCompanyProfile(cp: CompanyProfile) {
    this.user.company_profile = cp
    this.userObservable.next(this.user)
  }

  public setUserFromBackendWithToken(data: Token): Promise<void> {
    return new Promise((resolve, reject) => {
      const access_token = data.access_token
      const expires_in = data.expires_in

      this.serializationService.deserialize(User, data.user)
        .then((deserializedUser: User) => {
          this.user = deserializedUser
          this.setLocalTokenData(access_token, expires_in, deserializedUser)
          this.userObservable.next(this.user)
          resolve()
        })
        .catch(err => {
          this.sentryService.silentCaptureException(err)
          reject(err)
        })
    })
  }

  // sets localstorage token
  private setLocalTokenData(token: string, expires: number, user: object) {
    const tokenObject = {
      token: token,
      expires: this.calculateAccessTokenEnd(expires),
      user: user,
    }
    store.set('access_token', tokenObject)
  }

  private updateLocalStorageUser(user: User) {
    let token = store.get('access_token')
    token.user = user
    store.set('access_token', token)
  }

  public deleteLocalUser() {
    this.user = null
    store.remove('access_token')
    this.userObservable.next(null)
  }

  public setCookieConsent() {
    store.set('zt_cookie_consent', 'true')
    this.cookie_consent = true
  }

  public getCookieConsent() {
    if (this.cookie_consent === null) {
      this.cookie_consent = store.get('zt_cookie_consent') == 'true' ? true : false
    }

    return this.cookie_consent
  }

  // COMPANY PROFILE
  ///////////////////////////////////////

  public hasCompanyProfile() {
    return this.user && this.user.company_profile && this.user.company_profile.is_published ? true : false
  }

  // this is for robustnes
  // lets say one calls user.company_profile in a template and user is not set -> crash
  // this returns null but does not crash
  public getCompanyProfile(): CompanyProfile | null {
    return this.user && this.hasCompanyProfile() ? this.user.company_profile : null
  }

  public isPremiumCompanyProfile() {
    if (this.user.company_profile?.type == "OTHER" || this.user.company_profile?.type == 'CARPENTER_PLUS') {
      return true;
    }

    return false;
  }

  // updates the user with a company profile on backend
  public createCompanyProfile(type: 'OTHER' | "CARPENTER_PLUS" | 'CARPENTER'): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.user) {
        this.restService.post('frontendUser/createCompanyProfile', { type }, imageDefinitions.user.all_with_high_res_profile)
          .then((user: User) => {

            this.serializationService.deserialize(User, user)
              .then((deserialized: User) => {
                this.setUser(deserialized)
                resolve()
              })
              .catch(err => {
                this.sentryService.silentCaptureException(err)
                reject(err)
              })
          })
      } else {
        console.error('trying to set a company profile for a non existing user')
      }
    })
  }

  public publishCompanyProfile(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.restService.post('frontendUser/publishCompanyProfile', {}, imageDefinitions.user.all_with_high_res_profile)
        .then((cp: CompanyProfile) => {

          this.serializationService.deserialize(CompanyProfile, cp)
            .then((deserialized: CompanyProfile) => {

              deserialized.information_opening_hours = null;

              this.user.company_profile = deserialized
              this.setUser(this.user)
              resolve()
            })
            .catch(err => {
              this.sentryService.silentCaptureException(err)
              reject(err)
            })

        })
        .catch((err) => reject(err))
    })
  }

  public deleteProfile(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.restService.post('frontendUser/deleteProfile', {})
        .then(() => {
          this.deleteLocalUser()
          this.Router.navigate(['/blog'])
          resolve()
        })
        .catch((err) => reject(err))
    })
  }

  public deleteCompanyProfile(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.restService.post('frontendUser/deleteCompanyProfile', {})
        .then(() => {
          this.refreshUser()
          resolve()
        })
        .catch((err) => reject(err))
    })
  }

  public updateCompanyProfile(cp: CompanyProfile, shadow: boolean = false): Promise<void> {
    return new Promise((resolve, reject) => {

      // serialize
      this.serializationService.serialize(cp, CompanyProfile)
        .then((serialized: CompanyProfile) => {

          // send
          this.restService.post('frontendUser/updateCompanyProfile', serialized, imageDefinitions.user.all_with_high_res_profile)
            .then((response: User) => {

              // deserialize
              this.serializationService.deserialize(User, response)
                .then((deserialized: User) => {

                  if (!shadow)
                    this.setUser(deserialized)
                  else
                    this.user = deserialized

                  resolve()

                })
                .catch((err) => {
                  this.sentryService.silentCaptureException(err)
                  reject(err)
                })
            })
            .catch((err) => reject(err))
        })
        .catch((err) => {
          this.sentryService.silentCaptureException(err)
          reject(err)
        })
    })
  }

  public uploadCompanyProfileImage(formData: FormData, imageName: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.restService.post('frontendUser/uploadCompanyImages', formData, imageDefinitions.companyProfile.default, true)
        .then((cp: CompanyProfile) => {
          this.serializationService.deserialize(CompanyProfile, cp)
            .then((deserialized: CompanyProfile) => {
              this.user.company_profile = deserialized
              this.setUser(this.user)
              resolve()
            })
            .catch((err) => {
              this.sentryService.silentCaptureException(err)
              reject(err)
            })
        })
        .catch(err => reject(err))
    })
  }

  public deleteCompanyProfileImage(imageId: number): Promise<User> {
    return new Promise((resolve, reject) => {

      const data = {
        image_ids: [imageId],
      }

      this.restService.post('frontendUser/deleteCompanyImages', data, imageDefinitions.companyProfile.default)
        .then((user: User) => {
          this.serializationService.deserialize(User, user)
            .then((deserialized: User) => {
              this.setCompanyProfile(deserialized.company_profile)
              resolve(deserialized)
            })
            .catch((err) => {
              this.sentryService.silentCaptureException(err)
              reject(err)
            })
        })
        .catch(err => reject(err))
    })
  }

  public getUserById(id: number): Promise<User> {
    return new Promise((resolve, reject) => {
      const image_config = ImageConfig.merge([
        imageDefinitions.user.all_with_high_res_profile,
        imageDefinitions.blog.default_listview,
        imageDefinitions.advert.default_listview,
        imageDefinitions.job.default_listview,
      ]);

      this.restService.post('frontendUser/getUserProfile', { user_id: id }, image_config)
        .then((unserialized_entity: Object) => {

          this.serializationService.deserialize(User, unserialized_entity)
            .then((serialized_entity: User) => {
              resolve(serialized_entity)
            })
            .catch((err) => {
              this.sentryService.silentCaptureException(err)
              reject(err)
            })

        })
        .catch(err => reject(err))
    })
  }

  public getCompanyProfileById(id: number): Promise<CompanyProfile> {
    return new Promise((resolve, reject) => {
      const image_config = ImageConfig.merge([
        imageDefinitions.companyProfile.default,
        imageDefinitions.companyProfile.high_res_profile_image,
        imageDefinitions.blog.default_listview,
        imageDefinitions.advert.default_listview,
        imageDefinitions.job.default_listview,
      ]);

      this.restService.post('frontendUser/getCompanyProfileById', { id }, image_config)
        .then((unserialized_entity: User) => {

          this.serializationService.deserialize(User, unserialized_entity)
            .then((serialized_entity: User) => {

              if (typeof serialized_entity.company_profile == "undefined" || !serialized_entity.company_profile)
                reject()

              resolve(serialized_entity.company_profile)
            })
            .catch((err) => {
              this.sentryService.silentCaptureException(err)
              reject(err)
            })

        })
        .catch(err => reject())
    })
  }

  public sendPasswordForgotMail(email): Promise<void> {
    return new Promise((resolve, reject) => {
      this.restService.post('auth/forgotPassword/createToken', { email })
        .then(() => resolve())
        .catch(response => {

          let account_is_sso = false
          if (typeof response != "undefined" && typeof response.error != "undefined" && typeof response.error.errors != "undefined" && response.error.errors.messages.includes("ACCOUNT_IS_SSO"))
            account_is_sso = true

          reject({
            account_is_sso
          })
        })
    })
  }

  public sendPasswordResetRequest(password, token, id): Promise<void> {
    return new Promise((resolve, reject) => {
      this.restService.post('auth/forgotPassword/useToken', { password, token, id })
        .then(() => resolve())
        .catch(err => reject(err))
    })
  }

  /*
    this method determines if an entity was created by this currently logged in user
  */
  public entityIsOwn(e): boolean {

    // false means not yours
    // true means -> yours

    if (!this.user)
      return false

    if (e instanceof Entity) {
      if (e.profile_type == 'BUSINESS' && typeof e.frontend_user != "undefined" && e.frontend_user && typeof e.frontend_user.company_profile != "undefined" && e.frontend_user.company_profile && typeof this.user.company_profile != "undefined" && this.user.company_profile)
        return e.frontend_user.company_profile.id == this.user.company_profile.id

      else if (e.profile_type == 'PRIVATE' && e.frontend_user)
        return e.frontend_user.id == this.user.id

      return false
    }

    // comments for example
    else {
      return false
    }
  }

  /*
    For documentation please look at SsoLoginButtonsComponent.loginWithFacebook
  */
  public sendFacebookAccountConvertCreateToken(email: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.restService.post('auth/convertFacebook/createToken', { email })
        .then(() => resolve())
        .catch(response => {
          reject()
        })
    })
  }

  public sendFacebookAccountConvertUseToken(password, token, id): Promise<void> {
    return new Promise((resolve, reject) => {
      this.restService.post('auth/convertFacebook/useToken', { password, token, id })
        .then(() => resolve())
        .catch(err => reject(err))
    })
  }

}
