<mat-dialog-content class="notifications-dialog">
  <div class="notifications-headline-row">
    Benachrichtigungen
    <i (click)="close()" class="fal fa-times-circle"></i>
  </div>

  <div class="notifications-description-row" (click)="notificationService.markAllAsRead()">
    Alle Nachrichten löschen
  </div>

  <div class="notifications-row">
    <div class="notifications-row-item" *ngFor="let notification of notificationService.notifications"
      (click)="notificationService.click(notification)"
      (click)="notificationService.click(notification)"
      (click)="close()"
      >
      <div class="notifications-row-item-icon">
        <img src="/build/assets/images/messages.svg" alt="Notification Icon">
      </div>

      <div class="notifications-row-item-content">
        <h3>{{ notification.headline }}</h3>
        <p>{{ notification.description }}</p>

      </div>

      <div class="notifications-row-item-remove">
        <i (click)="notificationService.click(notification)" class="fal fa-times-circle"></i>
      </div>
    </div>
  </div>
</mat-dialog-content>