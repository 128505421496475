import { Serialize } from 'aautil'
import { AtomarDisplayUnit } from '@model/atomar-display-unit'
import { HelperService } from '@service/helper.service'
import { SafeStyle } from '@angular/platform-browser'
import { environment } from '@environments/environment'
import { Url } from './url'

export class ComputedImage extends AtomarDisplayUnit {

  /*
    this is the real URL
    unintuitive name comes from backend
  */
  @Serialize({
    from: {
      map: (cached: string, hs: HelperService, self: ComputedImage) => {

        // happens i.e. because backend is sending fallbackImage as null
        if (cached == null) {
          return null
        }

        // fixes the wrong url after updating https://staging.zimmerer-treff.comhttps://staging.zimmerer-treff.com/storage...
        const modifiedCachedURL = cached.substring(cached.indexOf('/storage'));

        self.safe = hs.trustStyleURL(environment.backendUrl + modifiedCachedURL)

        self.fullUrl = new Url(environment.backendUrl + modifiedCachedURL)

        return modifiedCachedURL
      },
      deps: [HelperService],
    }
  })
  public cached: string = null

  /*
    this "messy" structure (image.image) is because of the backend structure
    there cached_image is a "wrapper" for the real image object
  */
  @Serialize()
  public image: {
    purpose_key: string // read purpose_key explanation down below
    original_name: string
    id: number
    description: string
    order: number
  }

  /*
    purpose_key EXPLANATION

    this is a little weird
    
    lets imagine 4 images
    [
      {
        cached: "/storage/uploads/images/_cached/oukuU4bM82Tu6wWBhFQH5tjozdr6i_800.jpeg"
        purpose_key: "MAIN"
        image:{
          purpose_key: "MAIN,SLIDE"
        }
      },
      {
        cached: "/storage/uploads/images/_cached/oukuU4bM82Tu6wWBhFQH5tjozdr6i_1920.jpeg"
        purpose_key: "SLIDE"
        image:{
          purpose_key: "SLIDE"
        }
      },
      {
        cached: "/storage/uploads/images/_cached/R2qlRgfmSOMCjYbBJ41c5tjp0ttk3_1920.jpeg"
        purpose_key: "SLIDE"
        image:{
          purpose_key: "SLIDE"
        }
      },
      {
        cached: "/storage/uploads/images/_cached/rDD3FYYq4gSzUXr6g5eT5tjp0ttna_1920.jpeg"
        purpose_key: "SLIDE"
        image:{
          purpose_key: "SLIDE"
        }
      },
    ]

    as one can see the first one is in 800 resolution and is the main image for the list view etc.

    the second one is the same image in 1920 res and is the main image for the gallery
    it needs to be distinguishable from the other slide images because it always needs to be first

    hence only a combination of purpose_key and image.purpose_key are unambigous

    purpose_key == "MAIN" -> main image for list view
    purpose_key == "SLIDE" && image.purpose_key == "MAIN,SLIDE" -> main image for gallery
    purpose_key == "SLIDE" && image.purpose_key == "SLIDE" -> image for gallery

    I don't say thats particularly well designed :)
  */
  @Serialize()
  public purpose_key: string

  public width: string
  public height: string

  @Serialize()
  public isFallback?: boolean = false

  public safe: SafeStyle = null
  public fullUrl: Url = null

  @Serialize({
    from: {
      key: 'cached_thumbnail',
      map: (cachedThumbnail: string) => {
        return environment.backendUrl + cachedThumbnail
      }
    }
  })
  public cachedThumbnail: string;

}