export const messages = {
  
  undefinederror: 'Hoppla da ist uns ein Fehler unterlaufen. Wir würden uns sehr freuen wenn du ihn uns meldest damit wir unser System verbessern können.',
  imageuploaderror: 'Bild(er) konnte(n) nicht hochgeladen werden, bitte versuchen Sie es erneut.',
  imageUploadMaxSize: 'Die Bilder überschreiten die maximale Dateigröße von 10MB.',
  singleImageUploadMaxSize: 'Bilder konnten nicht hochgeladen werden. Die hochgeladenen Bilder dürfen je Hochladen 10 MB nicht überschreiten. Bitte versuche es erneut.',

  telInvalid: '*Die Telefonnummer muss zwischen 3 und 30 Zeichen lang sein und darf nur Zahlen, +, / und Leerzeichen enthalten',
  emailInvalid: '*Bitte gib eine gültige Email Adresse ein',
  birthdayInvalid: '*Bitte gib ein gültiges Geburtsdatum ein z.B. 03.03.1989. Keine Buchstaben.',

  companyNameInvalid: '*Firmenname muss zwischen 2 und 60 Zeichen lang sein.',

  favAdvertFetchError: 'error while fetching fav adverts',
  favBlogFetchError: 'error while fetching fav blogs',
  favWikiFetchError: 'error while fetching fav Wikis',
  favJobFetchError: 'error while fetching fav jobs',

  invalImageDescriptionLength: "Der Text darf maximal 200 Zeichen lang sein.",
  redeemBonusSuccess: 'Dein Bonus wurde eingelöst. Wir werden uns zeitnah bei dir melden.',

  texts: {

    profile: {
      blockheader: {
        infotexts: {
          names: `Teile der Community deinen Namen mit. Mit diesem Namen kannst Du Beiträge erstellen und in der Community bekannt werden.
          Willst Du lieber anonym bleiben oder bist unter deinem Spitznamen bekannt? Dann kannst Du hier deinen Nickname eintragen.
          In den Privatsphäreeinstellungen entscheidest Du selbst, ob Du mit deinem echten Namen oder mit deinem Nicknamen in der Community auftrittst.`,
          personal: 'Trage hier Dein Geschlecht und Geburtstag ein. Du selbst entscheidest in den Privatsphäreeinstellungen, ob diese Daten für andere Mitglieder sichtbar sind.',
          social: `Vernetze Dich mit anderen Mitgliedern und zeig, wo sie mehr von dir finden oder wo sie dich erreichen können. Du selbst entscheidest in den Privatsphäreeinstellungen, ob diese Daten für alle Mitglieder oder nur für dich und das Zimmerer-Treffpunkt Team intern sichtbar sind. Wenn Du nichts an den Einstellungen änderst, sind diese Daten vorerst nicht "für alle" sichtbar.`,
          address: 'Hier kannst Du deine Adresse eingeben, wo Du per Brief oder für Pakete erreichbar bist. Erst wenn Du deine Adresse eingibst, kann dir das Zimmerer-Treffpunkt Team deine Bonus-Gewinne zusenden. Du selbst entscheidest in den Privatsphäreeinstellungen, ob diese Daten für andere Mitglieder oder nur für das Zimmerer-Treffpunkt Team intern sichtbar sind.',
          career: 'Teile deinen Kollegen mit, wo Du Zimmerer bist und welche Ausbildung Du gemacht hast. Du selbst entscheidest in den Privatsphäreeinstellungen, ob diese Daten für andere Mitglieder sichtbar sind.',
          certificates: `Hier kannst Du ein Zertifikat hochladen und damit bestätigen, dass Du wirklich Zimmerer oder Dachdecker bist. Dafür bekommst Du 25 Woodcoins im Bonussystem, da uns wichtig ist, dass echte Handwerker in unserer Community sind. Als Zertifikat gilt: Foto von Dir in Zunftkleidung, Gesellenbrief, Meisterbrief, erkennbares Zertifikat einer Innung oder eines Verbandes (bspw. Meisterhaft-Urkunde, Schulungszeugnis)`,
          aboutme: `Hier kannst Du der Community mehr über dich und deinen Beruf erzählen. Warum bist Du Zimmerer?
          Was begeistert dich an deinem Beruf? Welche Baustellen magst Du am liebsten?
          Wo hast Du bereits gearbeitet?`
        }
      },
      settings: {
        infotexts: {
          comment: "Wenn andere Mitglieder deine Beiträge oder Kommentare kommentieren, erhältst Du von uns eine Benachrichtigung per Email. Wenn Du diese nicht mehr erhalten willst, kannst Du diese hier abschalten.",
          bonus: "Wenn Du aktiv in der Community bist, erhältst Du dafür von uns Woodcoins. Darüber wirst Du teilweise per Email informiert. Wenn Du diese Art der Benachrichtigungen nicht mehr willst, kannst Du die Benachrichtigungen hier abschalten.",
          email: "Wenn Du nicht mehr als die absolut notwendigen Benachrichtigungen per E-Mail von uns erhalten willst, kannst du sie hier abschalten."
        }
      }
    },

    businessprofile: {
      blockheader: {
        infotexts: {

          companyinformation: 'Schreibe hier in Stichpunkten einen kurzen Überblick über die Produkte und/oder Services deines Unternehmens. Das langjährige Bestehen spricht für ein Unternehmen: hier kann auch das Gründungsjahr hinterlegt werden.',
          description: 'Hier kannst Du dein Unternehmen beschreiben: Was bietet dein Unternehmen den Kunden? In welcher Region bietet das Unternehmen an? Welche Aufgaben erfüllt das Unternehmen? Wie ist die Unternehmensphilosophie? Was für einen Mehrwert bietet das Unternehmen dem Kunden?',
          current: 'Schreibe hier Neuigkeiten aus deinem Unternehmen: Was hat dein Unternehmen für neue Produkte/Dienstleistungen? Welche News verkündigt das Unternehmen derzeit auf seiner Webseite? Gab es personelle Änderungen? Welche News bieten einen Mehrwert für die Community?',
          products: 'Schreibe hier eine ausführliche Beschreibung deiner Produkte, Dienstleistungen und/oder Services: Welche Produkte/Dienstleistungen/Services vertreibt das Unternehmen? Was ist die Stärke des Unternehmens? Was sind die Vorteile der Produkte/Dienstleistungen und Services?',
          references: 'Hier kannst Du Bilder von deinen Produkten und Referenzen hochladen.',
          certificates: 'Hier kannst Du Bilder deines Unternehmens und des Teams hochladen.',
          contactpersons: 'Schreibe hier die Ansprechpartner deines Unternehmens hinein. Es ist möglich mehrere Ansprechpartner anzulegen und in Bereiche und Regionen zu trennen. So können die Mitglieder des Zimmerer-Treffpunkts gleich den richtigen Ansprechpartner suchen und kontaktieren.',
          socialmedia: 'Werde von unseren Mitgliedern besser in Social Media gefunden! Kopiere hier den Link deiner Social Media Seiten in die jeweiligen Spalten.',

        }
      }
    },

    cameratranslation: {
      promptLabelHeader: "Bild(er) hochladen",
      promptLabelCancel: "cancel",
      promptLabelPhoto: "Bild(er) aus der Gallery auswählen",
      promptLabelPicture: "Foto aufnehmen",
    }

  }
}
