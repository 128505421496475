<div class="first-row">



  <!-- footer logo column -->


  <div class="footer-logo">
    <img src="/build/assets/images/logo.svg" alt="Zimmerer Logo">
  </div>



  <!-- footer text column -->

  <div class="text-container">

    <p>
      {{footerFirstText}}
    </p>

    <button class="blue-button mobileGrowBtn" appLoginClickGuard *ngIf="!userService.isLoggedIn; else createPanel">
      Jetzt Mitglied werden
    </button>

    <p>
      {{footerSecondText}}
    </p>

    <ng-template #createPanel>

      <!-- TODO reuse the create-panel or create-entry-button component if possible -->
      <!-- TODO reuse in header as well -->
      <create-entry-button-with-tooltip [isMobileNavbar]="false"></create-entry-button-with-tooltip>

    </ng-template>




    <div class="bonus-system-link" (click)="goto('/boni/faq')">
      Mehr zum Bonussystem
    </div>


  </div>







  <!-- useful link column -->

  <ul class="footer-links-container">
    <li (click)="goto('/home')">Deine Vorteile</li>
    <li *ngIf="userService.isLoggedIn" (click)="goto('/profile/business')">Unternehmen</li>

    <li *ngIf="!userService.isLoggedIn" (click)="goto('/business')">Unternehmen</li>

    <li (click)="goto('/peripherals/about')">Über uns</li>
    <li (click)="goto('/peripherals/career')">Karriere</li>
    <li (click)="goto('/peripherals/contact')">Kontakt</li>
  </ul>



  <!-- social media column -->
  <div class="social-media-icons-container">
    <h4>Folge uns!</h4>


    <a target="_blank" [href]="socialMediaNumber.link" class="social-media-icons-item"
      *ngFor="let socialMediaNumber of socialMediaNumbers">

      <i *ngIf="socialMediaNumber.icon" [class]="socialMediaNumber.icon"></i>
      <img *ngIf="socialMediaNumber.image" [src]="socialMediaNumber.image" />

      <span class="social-media-icons-item-text">
        {{socialMediaNumber.number}} {{socialMediaNumber.who}}
      </span>
    </a>
  </div>



</div>



<div class="second-row">



  <ul>
    <li (click)="goto('/peripherals/agb')">AGB</li>
    <li (click)="goto('/peripherals/privacy')">Datenschutz</li>
    <li (click)="goto('/peripherals/impressum')">Impressum</li>
  </ul>

  <div class="copyright">&copy; {{getCurrentYear()}} Zimmerer-Treffpunkt</div>

</div>