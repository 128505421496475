import { ToastService } from '@service/toast/toast.service';
import { allowedImageExtensions } from '@configuration/image-definitions'
import { messages } from '@configuration/messages'
import { filesizeAllowed, getFileExtension } from '../misc/image-upload-helper';

export abstract class ImageUploadService {

  constructor(
    protected toastService: ToastService,
  ) { }

  public abstract uploadImage<T>(formData, purpose, shadow?): Promise<T>

  public onDropFile<T>(filelist: any, purpose: string, entityid: number, shadow: boolean = false): Promise<T> {
    return new Promise((resolve, reject) => {

      // check file count
      if (filelist.length > 10) {
        this.toastService.showError('Es können nicht mehr als 10 Bilder hochgeladen werden.')
        reject()
        return
      }

      // check image extensions
      for (let f of filelist) {
        const extension = getFileExtension(f.name).toLowerCase()

        if (!allowedImageExtensions.includes(extension)) {
          this.toastService.showError('Bilder mit dieser Dateiendung (' + extension + ') können nicht hochgeladen werden.')
          reject()
          return
        }
      }

      // check filesize
      if (!filesizeAllowed(filelist)) {
        this.toastService.showError(messages.imageUploadMaxSize)
        reject()
        return
      }

      // prepare
      const formData = new FormData()
      for (let i = 0; i < filelist.length; i++) {
        formData.append('images[' + i + '][image]', filelist[i], filelist[i].name)
        formData.append('images[' + i + '][purpose_key]', purpose)
        formData.append('id', entityid + '')
      }

      // upload
      this.uploadImage<T>(formData, purpose, shadow)
        .then((response: T) => resolve(response))
        .catch((e) => {
          console.log(e)
          // TODO maybe differentiate between generic request error
          this.toastService.showError(messages.singleImageUploadMaxSize)
          reject()
        })

    })
  }
}
