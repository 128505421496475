import { Injectable } from '@angular/core'
import { RestService } from '@service/rest/rest.service'
import { UserService } from '@service/user/user.service'
import { RegistrationUser } from '@model/registration-user/registration-user'
import { SerializationService } from 'aautil'
import { Token } from '@model/token'
import { RegistrationSsoUser } from '@model/registration-sso-user/registration-sso-user'
import { imageDefinitions } from '@configuration/image-definitions'
import { SentryService } from '@service/sentry.service'

export type VerificationInformation = {
  user_id: number,
  confirmation_code: string,
  firstname: string | undefined,
  lastname: string | undefined,
  privacy: boolean,
  newsletter: boolean,
};

@Injectable({
  providedIn: 'root',
})
export class RegistrationService {

  constructor(
    private restService: RestService,
    private userService: UserService,
    private serializationService: SerializationService,
    private sentryService: SentryService,
  ) { }

  public performRegistration(registrationUser: RegistrationUser): Promise<RegistrationUser | string> {
    return new Promise((resolve, reject) => {

      // serialize
      this.serializationService.serialize(registrationUser, RegistrationUser)
        .then(data => {

          // send
          this.restService.post('register/new', data)
            .then((response: Object) => {

              if (response == 'DRAFT_FOR_USER_ALREADY_EXISTS') {
                resolve('DRAFT_FOR_USER_ALREADY_EXISTS')
                return
              }

              if (response == 'BLACKLISTED_EMAIL') {
                resolve('BLACKLISTED_EMAIL')
                return
              }

              // deserialize
              this.serializationService.deserialize(RegistrationUser, response)
                .then(deserializedUser => resolve(deserializedUser))
                .catch(err => {
                  this.sentryService.silentCaptureException(err)
                  reject(err)
                })
            })
            .catch(err => reject(err))
        })
        .catch(err => {
          this.sentryService.silentCaptureException(err)
          reject(err)
        })

    })
  }

  public verifyEmail(userId: number, confirmationCode: string): Promise<void> {
    const confirmationData = {
      user_id: userId,
      confirmation_code: confirmationCode,
    }

    return new Promise((resolve, reject) => {
      this.restService.post('register/verifyMail', confirmationData)
        .then((data: Token) => {
          this.userService.setUserFromBackendWithToken(data)
            .then(() => {
              this.userService.userLoggedInObservable.next(null)
              resolve()
            })
        })
        .catch(err => reject(err))
    })
  }

  public loginSSO(userId: number, confirmationCode: string): Promise<void> {
    const confirmationData = {
      user_id: userId,
      confirmation_code: confirmationCode,
    }

    return new Promise((resolve, reject) => {
      this.restService.post('register/loginSSO', confirmationData, imageDefinitions.user.all_with_high_res_profile)
        .then((data: Token) => {
          this.userService.setUserFromBackendWithToken(data)
            .then(() => {
              this.userService.userLoggedInObservable.next(null)
              resolve()
            })
        })
        .catch(err => reject(err))
    })
  }

  public verifySSO(registrationUser: RegistrationSsoUser, userId: number, confirmationCode: string): Promise<void> {
    registrationUser.user_id = userId;
    registrationUser.confirmation_code = confirmationCode;

    return new Promise((resolve, reject) => {
      this.restService.post('register/verifySSO', registrationUser, imageDefinitions.user.all_with_high_res_profile)
        .then((data: Token) => {
          this.userService.setUserFromBackendWithToken(data)
            .then(() => {
              this.userService.userLoggedInObservable.next(null)
              resolve()
            })
        })
        .catch(err => reject(err))
    })
  }

  public async verifySSOAlternative(info: VerificationInformation): Promise<void> {
      const token: Token = await this.restService.post('register/verifySSO', info, imageDefinitions.user.all_with_high_res_profile);

      await this.userService.setUserFromBackendWithToken(token);

      this.userService.userLoggedInObservable.next(null);
  }

  public SSORegisterExists(user_id, confirmation_code): Promise<void> {
    return new Promise((resolve, reject) => {

      this.restService.post('register/SSOExist', { user_id, confirmation_code }, imageDefinitions.user.all_with_high_res_profile)
        .then((response) => {
          resolve()
        })
        .catch(err => reject(err))

    })
  }

  public cancelSSO(userId: number, confirmationCode: string): Promise<void> {
    const confirmationData = {
      user_id: userId,
      confirmation_code: confirmationCode,
    }
    return new Promise((resolve, reject) => {
      this.restService.post('register/cancelSSO', confirmationData)
        .then((data: Token) => resolve())
        .catch(err => reject(err))
    })
  }
}
