export const environment = {

  production: true,
  localDev: false,

  // used for websocket server -> client communication
  "socketio_server_url": 'https://zimmerer-treff.com',

  backendUrl: 'https://zimmerer-treff.com',
  publicFolder: '/build/', // is used for code compatibility with web project

  SentryDSN: "https://ace2ae038f7a4678a06d3e17f7cc6a3b@glitchtip.aceart.de/15",
}