import { FormGroup } from '@angular/forms'
import { Component, Output, OnInit, EventEmitter, Input } from '@angular/core'
import { RegistrationService } from '@service/registration.service'
import { RegistrationUser } from '@model/registration-user/registration-user'
import { modelToFormGroup } from 'aautil'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { messages } from '@configuration/messages'
import { ForgotPasswordDialogComponent } from '@modal/forgot-password-dialog/forgot-password-dialog.component'
import { MobileService } from '@service/mobile.service'
import { environment } from '@environments/environment'
import { ConfirmDialogComponent } from '@modal/confirm-dialog/confirm-dialog.component'
import { LoadingService } from '@service/loading.service'

@Component({
  selector: 'registration-dialog',
  templateUrl: './registration-dialog.component.html',
  styleUrls: ['./registration-dialog.component.scss'],
})
export class RegistrationDialogComponent implements OnInit {

  public environment = environment

  public messages = messages

  public form: FormGroup

  @Output() public status = new EventEmitter()

  public submitted: boolean = false

  @Input()
  public clickGuarded: boolean = false

  constructor(
    public dialogRef: MatDialogRef<RegistrationDialogComponent>,
    private registrationService: RegistrationService,
    public MatDialog: MatDialog,
    public MobileService: MobileService,
    private LoadingService: LoadingService
  ) { }

  public ngOnInit(): void {
    this.form = modelToFormGroup(new RegistrationUser())
  }

  public switch(e): void {
    this.status.emit(e)
  }

  public onSubmit() {

    this.submitted = true

    if (this.form.invalid)
      return

    this.LoadingService.startLoad()

    this.form.value.captcha = 'null'

    this.registrationService.performRegistration(this.form.value)
      .then((result: any) => {

        const setFormError = (errorKey) => {
          let errors = this.form.errors || {};
          errors[errorKey] = true;
          this.form.setErrors(errors);
        };

        if (result === "DRAFT_FOR_USER_ALREADY_EXISTS") {
          setFormError('mail_unique');
        } else if (result === "BLACKLISTED_EMAIL") {
          return;
        }

        // registration success
        else {

          this.close()

          const confirmConfig = {
            text: `
          <h2>Email Bestätigung.</h2>
          Nur noch ein Schritt zur Registrierung.<br>
          Wir haben dir eine Email gesandt. Bitte aktiviere deinen Account, indem Du in der Email deine Email-Adresse bestätigst.`,
            buttons: [
              {
                text: 'OK',
                value: 'abort',
              },
            ]
          }

          this.MatDialog.open(ConfirmDialogComponent, {
            data: confirmConfig,
            disableClose: false,
            panelClass: ConfirmDialogComponent.cssClass
          })

        }
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        this.LoadingService.stopLoad()
      })
  }

  public forgotPw() {
    this.dialogRef.close()
    this.MatDialog.open(ForgotPasswordDialogComponent, {
      panelClass: ForgotPasswordDialogComponent.cssClass
    })
  }

  public close() {
    this.dialogRef.close()
  }
}
