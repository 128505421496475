import { Injectable } from '@angular/core';
import { RestService } from './rest/rest.service';

@Injectable({
    providedIn: 'root'
})
export class MetricService {
    constructor(protected restService: RestService) { }

    async getMetrics(): Promise<any> {
        try {
            return await this.restService.get('metrics');
        } catch (error) {
            console.error('error fetching metrics:', error);
            throw error;
        }
    }
}
