export const appConfig = {
  credits: (<any>window).credits,
  maxSizeImage: 10485760, // bytes = 10 MB

  shareconfig_mobile: ['facebook', 'messenger', 'whatsapp'],
  shareconfig_desktop: ['facebook', 'whatsapp', 'email'],
  sharebuttonsmax: 4,
  // ['facebook', 'twitter', 'email', 'telegram', 'messenger', 'whatsapp', 'line', 'google']

  members: "1000",
  members_facebook: "1000",
  members_instagram: "1000",
  members_linkedin: "1000",
  members_facebook_group: ""
}
